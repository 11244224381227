.header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #0097d8; 
}

.logo {
    max-width: 150px; 
    height: auto;
}